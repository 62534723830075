
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































.icon {
  width: clamp(8rem, 4.761vw, 12rem);
  height: clamp(8rem, 4.761vw, 12rem);
  margin-bottom: 1.6rem;
  fill: $c-main;
}

.solution-single::v-deep {
  .single__title {
    font-size: clamp(3.6rem, 5.952vw, 10rem);
  }
}
